import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { getVideosMetrics } from "api/api";
import { getTopTags } from "api/graphql";
import Select from "components/Core/Inputs/Select";
import moment from "moment";
import GridCard from "./Card";
import TopVideoChart from "./TopVideosChart";
import AoiChart from "./AoiChart";
import VideoViewChart from "./VideoViewChart";
import { secondsToTime } from "helpers/utils";
import pages from "enums/pages";
import Header from "./DashboardHeader";
import { QUICK_FILTER_OPTIONS } from "enums/dropdown";
import { BEGINNING_DATE } from "enums/common";
import DateRangePicker, {
  RangeType,
} from "components/Core/DateRangePicker/DateRangePicker";
import { useDialog } from "context/dialog";

type ChartProps = { labels: string[]; labelLinks?: string[]; data: number[] };

const Dashboard = () => {
  const { showPageLoading } = useDialog();
  const [quickFilterVal, setQuickFilter] = useState<number>(
    QUICK_FILTER_OPTIONS[0].value,
  );

  const [totalVideosSent, setTotalVideosSent] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [fullViews, setFullViews] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [avgPlaytime, setAvgPlaytime] = useState(0);
  const [openedVideosCount, setOpenedVideosCount] = useState(0);
  const [topTags, setTopTags] = useState<ChartProps>();
  const [topVideos, setTopVideos] = useState<ChartProps>();
  const [videosViewsOverTime, setVideosViewsOverTime] = useState<ChartProps>();
  const [dates, setDates] = useState<RangeType>({
    fromDate: undefined,
    toDate: undefined,
  });
  const [label, setLabel] = useState("");

  const getVideosMetricsData = useCallback(
    async (dates?: any) => {
      showPageLoading(true);
      try {
        let fromDate = undefined;
        let toDate = undefined;
        if (dates) {
          fromDate = moment(dates.fromDate).format("YYYY-MM-DD");
          toDate = moment(dates.toDate).format("YYYY-MM-DD");
          setLabel(
            `${moment(fromDate).format("Do MMM YYYY")} - ${moment(toDate).format(
              "Do MMM YYYY",
            )}`,
          );
        } else {
          fromDate = quickFilterVal
            ? moment().subtract(quickFilterVal, "days").format("YYYY-MM-DD")
            : moment(BEGINNING_DATE).format("YYYY-MM-DD");
          toDate = moment().format("YYYY-MM-DD");
          setLabel(
            `${moment(fromDate).format("Do MMM YYYY")} - ${moment(toDate).format(
              "Do MMM YYYY",
            )}`,
          );
        }

        if (fromDate && toDate) {
          const data: any = await getVideosMetrics(fromDate, toDate);

          setTotalVideosSent(data.totalVideosSent);
          setTotalViews(data.videoViewCount);
          setFullViews(data.videoFullViewCount);
          setTotalPlayTime(data.totalPlaytime);
          setAvgPlaytime(data.avgPlaytime);
          setOpenedVideosCount(data.totalOpenedVideos);
          setTopVideos({
            labels: data.topVideos.map((v: any) => v.title),
            labelLinks: data.topVideos.map(
              (v: any) =>
                pages.VIDEO_DASHBOARD_PAGE.replace(":videoId", v.id) + `?name=${v.title}`,
            ),
            data: data.topVideos.map((v: any) => v.count),
          });

          let days: string[] = [];
          let viewsCounts: number[] = [];

          days = data.videosViewedOverTime.map((r: any) => r.day);
          viewsCounts = data.videosViewedOverTime.map((r: any) => r.count);

          setVideosViewsOverTime({
            labels: days,
            data: viewsCounts,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        showPageLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quickFilterVal],
  );

  const getTagsMetrics = useCallback(async () => {
    const topTags: any = await getTopTags();
    setTopTags({
      labels: topTags.map((t: any) => t.tag),
      data: topTags.map((t: any) => t.count),
    });
  }, []);

  useEffect(() => {
    getVideosMetricsData();
  }, [getVideosMetricsData]);

  useEffect(() => {
    getTagsMetrics();
  }, [getTagsMetrics]);

  const gridData = [
    {
      title: "Videos Sent",
      helperText: "videos Sent",
      stats: totalVideosSent,
    },
    {
      title: "Open",
      helperText: "videos opened",
      stats: openedVideosCount,
    },
    {
      title: "View",
      helperText: "views",
      stats: totalViews,
    },
    {
      title: "Full View",
      helperText: "full views",
      stats: fullViews,
    },
    {
      title: "Playtime",
      helperText: "",
      stats: secondsToTime(totalPlayTime) || "0s",
    },
    {
      title: "Avg playtime",
      helperText: "",
      stats: secondsToTime(avgPlaytime) || "0s",
    },
  ];

  return (
    <Container maxWidth={"xl"}>
      <Header>
        <Typography variant="h4">Dashboard</Typography>
      </Header>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <Typography variant="h5">{label}</Typography>
        </Box>
        <Stack direction={"row"} gap={1}>
          <Box width={200}>
            <Select
              id="quickFilter"
              label="Quick Filter"
              name="quickFilter"
              options={QUICK_FILTER_OPTIONS}
              value={quickFilterVal}
              onChange={(val: number) => setQuickFilter(val)}
            />
          </Box>
          <Box width={260}>
            <DateRangePicker
              label="Select Dates"
              setDateRange={setDates}
              onCalendarClose={() => getVideosMetricsData(dates)}
            />
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={2} mb={3}>
        {gridData.map((d: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl>
            <GridCard title={d.title} helperText={d.helperText} stats={d.stats} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={8}>
          <TopVideoChart
            labels={topVideos?.labels || []}
            data={topVideos?.data || []}
            labelsLink={topVideos?.labelLinks}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: "auto" }}>
          <AoiChart labels={topTags?.labels || []} data={topTags?.data || []} />
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            mb={2}
            spacing={1}
          >
            <Typography fontSize={18} mb={3}>
              Videos Viewed Over Time
            </Typography>
          </Stack>
          <VideoViewChart
            labels={videosViewsOverTime?.labels || []}
            data={videosViewsOverTime?.data || []}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default Dashboard;
