import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";

// import { deleteTag,getTags } from "api/api";
import { deleteTag as gqlDeleteTag, getTags as gqlGetTags } from "api/graphql";
import { TagType } from "typings/tag";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreOptions from "components/Core/MoreOptions";
import { useCurrentUser } from "context/user";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import AddUpdateTag from "./AddUpdateTag";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";

type TagsResponse = {
  rows: TagType[];
  count: number;
};

const Tag = () => {
  const limit = 20;
  const { confirm, alertSuccess, alertError, showPageLoading } = useDialog();

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [tags, setTags] = useState<TagType[]>([]);
  const [currentTag, setCurrentTag] = useState<TagType>();

  const [showAddTagModal, setShowAddTagModal] = useState(false);

  const { user } = useCurrentUser();

  const TABLE_HEAD = [
    { id: "tag", label: "Tag" },
    { id: "createdDate", label: "Created Date" },
    { id: "", label: "" },
  ];

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const getData = useCallback(() => {
    if (!user?.account?.id) return;
    showPageLoading(true);
    gqlGetTags(user?.account?.id, limit, page)
      .then((res) => {
        const data = res as TagsResponse;
        setTags(data.rows);
        setTotalCount(data.count);
      })
      .catch()
      .finally(() => showPageLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, limit, page]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDeleteTag = async (id: string) => {
    showPageLoading(true);
    try {
      await gqlDeleteTag(id);
      alertSuccess("Tag deleted successfully.");
      getData();
    } catch (err: any) {
      alertError(err.message);
    }
    showPageLoading(false);
  };

  const deleteTagConfirmation = (id: string, tag: string) => {
    confirm({
      title: "Delete Tag!",
      content: `Are you sure you want to delete ${tag} tag?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => handleDeleteTag(id),
    });
  };

  return (
    <Card>
      <AddUpdateTag
        openModal={showAddTagModal}
        setOpenModal={setShowAddTagModal}
        tag={currentTag}
        refreshList={getData}
      />

      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((row: TagType) => {
                const { id, tag, account_id, created_at } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell>{tag}</TableCell>
                    <TableCell>{formatDate(created_at)}</TableCell>
                    <TableCell align="right">
                      {user?.account.id === account_id && (
                        <MoreOptions
                          options={[
                            {
                              label: "Edit Tag",
                              icon: <EditIcon width={24} height={24} />,
                              onClick: () => {
                                setCurrentTag({ id, tag });
                                setShowAddTagModal(true);
                              },
                            },
                            {
                              label: "Delete Tag",
                              icon: <DeleteIcon width={24} height={24} />,
                              onClick: () => deleteTagConfirmation(id, tag),
                            },
                          ]}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </Scrollbar>
    </Card>
  );
};

export default Tag;
