import * as yup from "yup";
import errors from "enums/errors";
import moment from "moment";
import HOLIDAYS from "enums/holidays";

export default {
  validate_first_name: yup.string().required(errors.VALIDATION.FIRST_NAME.REQUIRED),
  validate_last_name: yup.string().required(errors.VALIDATION.LAST_NAME.REQUIRED),
  validate_email: yup
    .string()
    .email(errors.VALIDATION.EMAIL.VALID_EMAIL)
    .required(errors.VALIDATION.EMAIL.REQUIRED),
  validate_password: yup
    .string()
    .min(8, errors.VALIDATION.PASSWORD.MIN_8)
    .matches(/[0-9]/, errors.VALIDATION.PASSWORD.CONTAIN_NUMBER)
    .matches(/[a-z]/, errors.VALIDATION.PASSWORD.CONTAIN_LOWERCASE)
    .matches(/[A-Z]/, errors.VALIDATION.PASSWORD.CONTAIN_UPPERCASE)
    .matches(
      /(?=.*[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`])/,
      errors.VALIDATION.PASSWORD.CONTAIN_SPECIAL_CHAR,
    )
    .required(errors.VALIDATION.PASSWORD.REQUIRED),
  validate_tags: yup.array().min(1).of(yup.string().uuid().required()).required(),
  validate_channels: yup.array().min(1).of(yup.string().uuid().required()).required(),
  validate_password_required: yup.string().required(errors.VALIDATION.PASSWORD.REQUIRED),
  validate_phone: yup
    .string()
    .required(errors.VALIDATION.PHONE.REQUIRED)
    .min(10, errors.VALIDATION.PHONE.VALID_PHONE)
    .max(10, errors.VALIDATION.PHONE.VALID_PHONE),
  validate_phone_not_required: yup
    .string()
    .min(10, errors.VALIDATION.PHONE.VALID_PHONE)
    .max(10, errors.VALIDATION.PHONE.VALID_PHONE),
  validate_medical_designation: yup
    .string()
    .required(errors.VALIDATION.MEDICAL_DESIGNATION.REQUIRED),
  validate_institution: yup.string().required(errors.VALIDATION.INSTITUTION.REQUIRED),
  validate_clinic: yup
    .string()
    .test("clinicName", errors.VALIDATION.CLINIC_NAME.REQUIRED, (value, data) => {
      if (data.parent.institution === "other" && !value) {
        return false;
      }
      return true;
    }),
  validate_zip: yup
    .string()
    .required(errors.VALIDATION.ZIP.REQUIRED)
    .test("zip", errors.VALIDATION.ZIP.VALID_ZIP, (value) => {
      if (!value) return false;
      return (
        /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ||
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(value)
      );
    }),
  validate_video_file: yup
    .mixed()
    .required(errors.VALIDATION.VIDEO_FILE.REQUIRED)
    .test("fileType", errors.VALIDATION.VIDEO_FILE.VALID_FILE, (value) =>
      /video\/(mp4|mov|avi|m4v)$/.test(value && value.type),
    ),
  validate_link: yup
    .string()
    .test("videoLink", errors.VALIDATION.LINK.VALID_LINK, (value) => {
      if (!value) return true;
      return /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\.(mp4|mov|avi|m4v)(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
        value,
      );
    }),
  validate_short_link: yup
    .string()
    .required(errors.VALIDATION.LINK.REQUIRED)
    .test("videoLink", errors.VALIDATION.LINK.VALID_LINK, (value) => {
      if (!value) return true;
      return /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\.(mp4|mov|avi|m4v)(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
        value,
      );
    }),
  validate_doctor_name: yup.string().required(errors.VALIDATION.DOCTOR_NAME.REQUIRED),
  validate_series: yup
    .mixed()
    .required()
    .test("series", "series is not valid", (val) => {
      return ["object", "string"].includes(typeof val);
    }),
  validate_video_title: yup.string().required(errors.VALIDATION.VIDEO_TITLE.REQUIRED),
  validate_long_video_title: yup
    .string()
    .test(
      "longVideoTitle",
      errors.VALIDATION.LONG_VIDEO_TITLE.REQUIRED,
      (value, data) => {
        if (data.parent.longVideoLink && !value) {
          return false;
        }
        return true;
      },
    ),
  validate_description: yup.string(),
  validate_company_name: yup.string().required(errors.VALIDATION.COMPANY_NAME.REQUIRED),
  validate_slug: yup.string().required(errors.VALIDATION.SLUG.REQUIRED),
  validate_mds: yup.array().test("mds", errors.VALIDATION.MDS.REQUIRED, (arr) => {
    if (arr) {
      return arr.length >= 1;
    }
    return false;
  }),
  validate_thumbnail_file: yup
    .mixed()
    .test("thumbnailFile", errors.VALIDATION.THUMBNAIL_FILE.SIZE, (file) => {
      if (file) {
        return file.size / 1024 <= 5 * 1024;
      }
      return true;
    }),
  validate_account_name: yup.string().required(errors.VALIDATION.ACCOUNT_NAME.REQUIRED),
  validate_account_slug: yup.string().required(errors.VALIDATION.ACCOUNT_SLUG.REQUIRED),
  validate_account_logo: yup
    .mixed()
    .required()
    .test("logo", errors.VALIDATION.ACCOUNT_LOGO.REQUIRED, (file) => {
      if (!file) {
        return false;
      }
      return true;
    }),
  optional_date_time: yup
    .date()
    .nullable()
    .min(new Date(), errors.VALIDATION.DATETIME.PASTDATE),
  short_video_message: yup
    .string()
    .nullable()
    .test(
      "Message For Short Video",
      errors.VALIDATION.SHORT_VIDEO_MESSAGE.INCLUDE_PLACEHOLDERS,
      (message) =>
        message ? ["{{LINK}}"].every((value) => message.includes(value)) : true,
    ),
  long_video_message: yup
    .string()
    .nullable()
    .test(
      "Message For Long Video",
      errors.VALIDATION.LONG_VIDEO_MESSAGE.INCLUDE_PLACEHOLDERS,
      (message) =>
        message ? ["{{LINK}}"].every((value) => message.includes(value)) : true,
    ),
  authors_message: yup
    .array()
    .min(1)
    .of(yup.string().uuid().required())
    .required(errors.VALIDATION.AUTHORS.REQUIRED),
  validate_current_password: yup
    .string()
    .required(errors.VALIDATION.CURRENT_PASSWORD.REQUIRED),
  validate_confirm_password: yup
    .string()
    .required(errors.VALIDATION.CONFIRM_PASSWORD.REQUIRED)
    .test(
      "Confirm Password",
      errors.VALIDATION.CONFIRM_PASSWORD.NOT_MATCHING,
      (value, data) => data.parent.newPassword === value,
    ),
  validate_scheduled_on: yup
    .date()
    .nullable()
    .test("Schedule Video", errors.VALIDATION.SCHEDULED_ON.REQUIRED, (value, data) => {
      if (!data.parent.makeActiveOn && !value) {
        return false;
      }
      return true;
    })
    .test(
      "Schedule Video",
      errors.VALIDATION.SCHEDULED_ON.DEPENDS_ON_MAKE_ACTIVE_ON,
      (value, data) => {
        if (data.parent.makeActiveOn && value) {
          return false;
        }
        return true;
      },
    )
    .test(
      "Schedule Video",
      errors.VALIDATION.SCHEDULED_ON.SCHEDULED_ON_HOLIDAY,
      (value) => {
        const h: any = HOLIDAYS;
        const holidays = h[moment(value).year()];
        const day = moment(value).day();
        if (
          value &&
          ([0, 6].includes(day) ||
            holidays?.includes(moment(value)?.format("YYYY-MM-DD")))
        ) {
          return false;
        }
        return true;
      },
    ),
  validate_make_active_on: yup
    .date()
    .nullable()
    .min(new Date(), errors.VALIDATION.DATETIME.PASTDATE)
    .test(
      "Make Video Active",
      errors.VALIDATION.MAKE_ACTIVE_ON.REQUIRED,
      (value, data) => {
        if (!data.parent.scheduledOn && !value) {
          return false;
        }
        return true;
      },
    )
    .test(
      "Make Video Active",
      errors.VALIDATION.MAKE_ACTIVE_ON.DEPENDS_ON_SCHEDULED_ON,
      (value, data) => {
        if (data.parent.scheduledOn && value) {
          return false;
        }
        return true;
      },
    ),
  validate_channel_name: yup.string().required(errors.VALIDATION.CHANNEL_NAME.REQUIRED),
  validate_clinic_name: yup.string().required(errors.VALIDATION.CLINIC_NAME.REQUIRED),
  validate_tag_name: yup.string().required(errors.VALIDATION.TAG_NAME.REQUIRED),
  validate_account_id: yup.string().required(errors.VALIDATION.ACCOUNT_ID.REQUIRED),
  validate_message_type: yup.string().required(errors.VALIDATION.MESSAGE_TYPE.REQUIRED),
  validate_message_text: yup.string().required(errors.VALIDATION.MESSAGE_TEXT.REQUIRED),
  validate_account_id_optional: yup.string().uuid().optional(),
};
