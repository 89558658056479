import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";
import { updateAccount } from "api/api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreOptions from "components/Core/MoreOptions";

import { StyledTableCell } from "components/Core/Table/Table.styles";
import { v4 as uuid } from "uuid";
import AddWelcomeVideo from "./AddWelcomeVideo";
import { useDialog } from "context/dialog";
import { AccountType, WelcomeVideo } from "typings/account";
import { formatDate } from "helpers/utils";

//import { getAccount} from "api/api";
import { getAccount as gqlGetAccount } from "api/graphql";

const WelcomeVideos = () => {
  const { confirm, showPageLoading } = useDialog();
  const [account, setAccount] = useState<AccountType>();
  const [videos, setVideos] = useState<any[]>([]);

  const [showWelcomVideoModal, setShowWelcomVideoModal] = useState(false);
  const [editVideoId, setEditVideoId] = useState("");

  const TABLE_HEAD = [
    { id: "thumbnail", label: "Thumbnail" },
    { id: "sendOorder", label: "Send Order" },
    { id: "createdAt", label: "Created Date" },
    { id: "", label: "" },
  ];

  const fetchWelcomeVideos = useCallback(async () => {
    try {
      showPageLoading(true);
      const account: any = await gqlGetAccount();
      setAccount(account);
      if (account?.settings?.welcomeVideos) {
        setVideos(
          account.settings.welcomeVideos?.sort(
            (video1: WelcomeVideo, video2: WelcomeVideo) => {
              if (video1.order > video2.order) {
                return 1;
              } else if (video1.order < video2.order) {
                return -1;
              }
              return 0;
            },
          ),
        );
      } else if (account?.settings?.welcomeVideoLink) {
        setVideos([
          {
            id: uuid(),
            video: account?.settings?.welcomeVideoLink,
            thumbnail: account?.settings?.welcomeVideoThumbnail,
            order: 1,
          },
        ]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmVideoDeletion = (id: string) => {
    confirm({
      title: "Delete Video!",
      content: `Are you sure you want to delete this video?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: async () => {
        await updateAccount({
          settings: {
            ...(account?.settings || {}),
            welcomeVideos: videos.filter((video) => video.id !== id),
          },
        });
        fetchWelcomeVideos();
      },
    });
  };

  useEffect(() => {
    fetchWelcomeVideos();
  }, [fetchWelcomeVideos]);

  useEffect(() => {
    if (showWelcomVideoModal === false) {
      setEditVideoId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWelcomVideoModal]);

  return (
    <Card>
      {account && (
        <AddWelcomeVideo
          editVideoId={editVideoId}
          openModal={showWelcomVideoModal}
          setOpenModal={setShowWelcomVideoModal}
          refreshList={fetchWelcomeVideos}
          account={account}
        />
      )}

      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {videos.map((row: WelcomeVideo) => {
                const { id, thumbnail, order, created_at } = row;
                console.log(created_at);

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell>
                      <img src={thumbnail} width="220px" height="130px" />
                    </TableCell>

                    <TableCell>{order}</TableCell>

                    <TableCell>{formatDate(created_at)}</TableCell>

                    <TableCell align="right">
                      <MoreOptions
                        options={[
                          {
                            label: "Edit Video",
                            icon: <EditIcon width={24} height={24} />,
                            onClick: () => {
                              setEditVideoId(id);
                              setShowWelcomVideoModal(true);
                            },
                          },
                          {
                            label: "Delete Video",
                            icon: <DeleteIcon width={24} height={24} />,
                            onClick: () => confirmVideoDeletion(id),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
};

export default WelcomeVideos;
