import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";

import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import InputField from "components/Core/Inputs/Input";
import Modal from "components/Core/Modal";
import FileUpload from "components/Core/Inputs/FileUpload";
import errors from "enums/errors";

import { getMuxVideoLink, getVideoUploadUrl, updateAccount } from "api/api";
import { UPLOAD_DIR } from "enums/common";
import { useDialog } from "context/dialog";
import CircularProgress from "components/Core/CircularProgress";
import * as UpChunk from "@mux/upchunk";
import { AccountType, WelcomeVideo } from "typings/account";
import moment from "moment";
import { uploadImage } from "helpers/image";

const MUX_BASE_URL = "https://stream.mux.com";

type Props = {
  editVideoId?: string;
  account: AccountType;
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  refreshList: () => void;
};

const AddWelcomeVideo = ({
  editVideoId,
  account,
  openModal,
  setOpenModal,
  refreshList,
}: Props) => {
  const { alertError, alertSuccess } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  let videos = useMemo(() => {
    return account?.settings?.welcomeVideos || [];
  }, [account]);

  const uploadVideo = (videoFile: File, data: any) =>
    new Promise((resolve, reject) => {
      try {
        const upload = UpChunk.createUpload({
          endpoint: data.url,
          file: videoFile,
          chunkSize: 5120,
        });

        // Subscribe to events
        upload.on("error", (error: any) => {
          alertError(error.detail);
        });

        // upload.on("progress", (progress: any) => {
        //   setProgress(progress.detail);
        // });

        upload.on("success", async () => {
          const videoDetail: any = await getMuxVideoLink(data.id);
          let fileName;
          if (videoDetail.max_stored_resolution === "HD") {
            fileName = "high.mp4";
          } else {
            fileName = "low.mp4";
          }

          // setProgress(0);

          return resolve(`${MUX_BASE_URL}/${videoDetail.playback_ids[0].id}/${fileName}`);
        });
      } catch (err) {
        reject(err);
      }
    });

  const formik = useFormik({
    initialValues: {
      thumbnail: "",
      video: "",
      videoLink: "",
      order: "",
    },
    enableReinitialize: true,
    // validationSchema: VALIDATION.general_setting,
    onSubmit: async (values: any) => {
      setIsLoading(true);
      try {
        const videoId = uuid();

        // upload thumbnail
        setProgress(0);
        let thumbnail = "";
        if (values.thumbnail && typeof values.thumbnail !== "string") {
          thumbnail = await uploadImage(values.thumbnail, videoId, UPLOAD_DIR.THUMBNAIL);
          setProgress(10);
        } else {
          thumbnail = values.thumbnail;
          setProgress(10);
        }

        // upload video
        let video: any = "";
        if (values.video) {
          const muxUploadObject = await getVideoUploadUrl(videoId, "welcome_video");
          setProgress(30);
          video = await uploadVideo(values.video, muxUploadObject);
          setProgress(80);
        } else {
          video = values.videoLink;
          setProgress(80);
        }

        // save videos
        const welcomeVideo: WelcomeVideo = {
          id: editVideoId || videoId,
          thumbnail,
          video: video,
          order: values.order,
          created_at: moment().format("YYYY-MM-DD HH:mm:ssZ"),
        };

        if (editVideoId) {
          videos = videos.filter((video) => video.id !== editVideoId);
        }

        await updateAccount({
          settings: {
            ...(account.settings || {}),
            welcomeVideos: [...videos, welcomeVideo],
          },
        });
        setProgress(100);

        refreshList();

        alertSuccess("Welcome video get added successfully!");
      } catch (err: any) {
        console.log(err);
        alertError(err.message);
      }
      setIsLoading(false);
      setOpenModal(false);
    },
  });

  useEffect(() => {
    if (openModal === false) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    if (editVideoId) {
      const video = videos.find((video) => video.id === editVideoId);
      if (video) {
        formik.setFieldValue("thumbnail", video.thumbnail);
        formik.setFieldValue("videoLink", video.video);
        formik.setFieldValue("order", video.order);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editVideoId]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)} width="600px">
        <Typography variant="h4" gutterBottom align="center">
          Welcome Video
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              id="order"
              name="order"
              label="Order"
              value={formik.values.order}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.order && Boolean(formik.errors.order)}
              helperText={formik.touched.order && formik.errors.order}
              fullWidth
            />

            <FileUpload
              id="thumbnail"
              label="Thumbnail"
              name="thumbnail"
              accept="image"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files !== null && event.target?.files?.length > 0) {
                  formik.setFieldValue("thumbnail", event.target.files[0]);
                  const size = event.target.files[0].size;
                  if (size / 1024 <= 5 * 1024) {
                    formik.setErrors({
                      thumbnail: errors.VALIDATION.THUMBNAIL_FILE.SIZE,
                    });
                  }
                }
              }}
              onDrop={(event: React.DragEvent<HTMLElement>) => {
                formik.setFieldValue("thumbnail", event.dataTransfer.files[0]);
                const size = event.dataTransfer?.files[0]?.size ?? 0;
                if (size / 1024 <= 5 * 1024) {
                  formik.setErrors({
                    thumbnail: errors.VALIDATION.THUMBNAIL_FILE.SIZE,
                  });
                }
              }}
              imageButton
              image={{
                url: formik.values.thumbnail,
              }}
              error={formik.touched.thumbnail && Boolean(formik.errors.thumbnail)}
              helperText={formik.touched.thumbnail && formik.errors.thumbnail}
            />

            <FileUpload
              id="video"
              label="Video"
              accept="video"
              hoverLabel="Click or drag file here and click upload button"
              dropLabel="Drop file here and click upload button"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files !== null && event.target?.files?.length > 0) {
                  formik.setFieldValue("video", event.target.files[0]);
                }
              }}
              onDrop={(event: React.DragEvent<HTMLElement>) => {
                formik.setFieldValue("video", event.dataTransfer.files[0]);
              }}
              imageButton
              helperText={"Allowed *.mp4, *.m4v, *.avi, *.mov"}
            />

            <Typography fontWeight={600} align="center">
              OR
            </Typography>

            <InputField
              id="videoLink"
              name="video Link"
              label="Video Link"
              value={formik.values.videoLink}
              onChange={(e: any) => formik.setFieldValue("videoLink", e.target.value)}
              onBlur={(e: any) => formik.setFieldValue("videoLink", e.target.value)}
              error={formik.touched.videoLink && Boolean(formik.errors.videoLink)}
              helperText={formik.touched.videoLink && formik.errors.videoLink}
              fullWidth
            />

            <LoadingButton
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
              loadingIndicator={
                <CircularProgress variant="determinate" value={progress} />
              }
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <Box p={2} textAlign={"right"}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setOpenModal(true);
          }}
          size="large"
        >
          Add Welcome Video
        </Button>
      </Box>
    </>
  );
};

export default AddWelcomeVideo;
