import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Select,
  Stack,
} from "@mui/material";
import { useParams } from "react-router";

import InputField from "components/Core/Inputs/Input";

import { getTags, reSendCustomizationLink, updateSubscriber } from "api/api";
import MultiSelect from "components/Core/Inputs/MultiSelect";
import { columnMeta } from "./Subscribers";
import { MEDICAL_DESIGNATIONS, ONBOARDING_STATUS_OPTIONS } from "enums/dropdown";
import VALIDATION from "helpers/validation_schemas";
import ErrorComponent from "components/Core/Error/Error";
import { useDialog } from "context/dialog";
import { useCurrentUser } from "context/user";

// import { getChannelsDropdown,   getPublicClinics, getSubscriber} from "api/api";
import { getChannelsDropdown as gqlGetChannelsDropdown } from "api/graphql";
import { getClinicsDropdown as gqlGetPublicClinics } from "api/graphql";
import { getSubscriber as gqlGetSubscriber } from "api/graphql";

const AddEditSubscriber = () => {
  const { id } = useParams();
  const { alertSuccess, alertError, showPageLoading } = useDialog();

  const { user } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(false);

  const [accountId, setAccountId] = useState("");
  const [tagsOptions, setTagsOptions] = useState<any>([]);
  const [clinics, setClinics] = useState<any>([]);
  const [channels, setChannels] = useState<any>([]);
  const [subscriber, setSubscriber] = useState<any>();

  const [errors, setErrors] = useState<any>([]);

  const [meta, setMeta] = useState<any>();

  const formik = useFormik({
    initialValues: {
      firstName: subscriber?.first_name || "",
      lastName: subscriber?.last_name || "",
      email: subscriber?.email || "",
      mobile: subscriber?.mobile || "",
      medicalDesignation: subscriber?.medical_designation || "",
      accountName: subscriber?.account_name || "",
      accountId: subscriber?.account_id || "",
      status: subscriber?.status || "",
      clinicId: subscriber?.clinic_id || "",
      clinicName: "",
      region: subscriber?.region || "",
      tags: subscriber?.tags?.map(({ id }: any) => id) || [],
      channels: subscriber?.channels || [],
      referredBy: subscriber?.referred_by || "",
      zip: subscriber?.zip || "",
      onboardingStatus: subscriber?.onboarding_status || "",
      sendType: subscriber?.send_type || {
        sms: true,
        email: false,
      },
      isDoctor: subscriber?.subscriber_type === "doctor",
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.update_subscriber_schema,
    onSubmit: async (values) => {
      if (!id) return;
      setErrors([]);
      setIsLoading(true);
      try {
        const data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          mobile: values.mobile,
          medicalDesignation: values.medicalDesignation,
          clinicId: values.clinicId,
          clinicName: values.clinicName,
          tags: values.tags,
          zip: values.zip,
          channels: values.channels,
          onboardingStatus: values.onboardingStatus,
          sendType: values.sendType,
          isDoctor: values.isDoctor,
        };
        await updateSubscriber(id, data);
        alertSuccess("Subscriber updated successfully");
      } catch (err: any) {
        console.log(err);
        if (err.details) {
          alertError(err.details);
        } else {
          alertError(err.message);
        }
      }

      setIsLoading(false);
    },
  });

  const loadSubscriber = useCallback(async () => {
    if (id) {
      showPageLoading(true);
      try {
        const [clinics, subs]: any = await Promise.all([
          gqlGetPublicClinics(),
          gqlGetSubscriber(id),
        ]);
        const subscriber = subs.rows[0];
        setSubscriber(subscriber);
        setClinics(clinics.data);
        setAccountId(subscriber.account_id);

        const channels: any = await gqlGetChannelsDropdown(subscriber.account_id);
        setChannels(
          channels.map((row: any) => ({
            label: row.name,
            value: row.id,
            disabled: row.is_default,
          })),
        );
      } catch (err) {
        console.log(err);
      } finally {
        showPageLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadAois = useCallback(async () => {
    if (accountId) {
      try {
        const aois: any = await getTags(accountId, 1000);
        setTagsOptions(aois.rows.map((row: any) => ({ label: row.tag, value: row.id })));
      } catch (err) {
        console.log(err);
      }
    }
  }, [accountId]);

  useEffect(() => {
    loadSubscriber();
  }, [loadSubscriber]);

  useEffect(() => {
    loadAois();
  }, [loadAois]);

  useEffect(() => {
    if (subscriber) {
      const meta = columnMeta(subscriber);
      setMeta(meta);
    }
  }, [subscriber]);

  const reSendCustomization = async (id: string) => {
    showPageLoading(true);
    try {
      await reSendCustomizationLink(id);
      alertSuccess("Customization link sent successfully.");
    } catch (err) {
      console.log(err);
      alertError("Something went wrong! please try again after sometime.");
    }
    showPageLoading(false);
  };

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Card sx={{ p: 5 }}>
        <Stack direction={"row"} mb={2} justifyContent={"space-between"} gap={2}>
          <Stack direction={"row"} gap={2} style={{ textTransform: "capitalize" }}>
            <Box>
              Approve Status:{" "}
              <Chip label={meta?.status?.label} color={meta?.status?.color} />
            </Box>
            <Box>
              Onboarding Status:{" "}
              <Chip label={meta?.onboarding?.label} color={meta?.onboarding?.color} />
            </Box>
          </Stack>
          <Button
            variant="contained"
            onClick={() => subscriber && reSendCustomization(subscriber?.id)}
          >
            Send Customization Link
          </Button>
        </Stack>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              label="First Name"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              fullWidth
            />

            <InputField
              label="Last Name"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              fullWidth
            />

            <InputField
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              disabled
              // onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />

            <InputField
              label="Mobile"
              id="mobile"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
              fullWidth
            />

            <Box>
              <InputLabel htmlFor="clinics">Clinics</InputLabel>
              <Select
                native
                style={{ width: "100%" }}
                id="clinicId"
                name="clinicId"
                value={formik.values.clinicId}
                onChange={(e: any) => {
                  formik.setFieldValue("clinicId", e.target.value);

                  const dataset = e.target.selectedOptions[0].parentNode.dataset;
                  if (e.target.value !== "other") {
                    formik.setFieldValue("accountId", dataset.id);
                    formik.setFieldValue("accountName", dataset.name);
                    setAccountId(dataset.id);
                  } else {
                    formik.setFieldValue("accountId", "");
                    formik.setFieldValue("accountName", "");
                  }
                }}
                error={formik.touched.clinicId && Boolean(formik.errors.clinicId)}
              >
                {clinics.map((account: any) => (
                  <optgroup
                    key={account.id}
                    label={account.name}
                    data-id={account.id}
                    data-name={account.name}
                  >
                    {account?.clinics?.map((clinic: any) => (
                      <option key={clinic.id} value={clinic.id}>
                        {clinic.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
                <option key={"other"} value={"other"}>
                  Other
                </option>
              </Select>
              {formik.touched.clinicId && (
                <FormHelperText error={!!formik.errors.clinicId}>
                  {formik.touched.clinicId && formik.errors.clinicId}
                </FormHelperText>
              )}

              {formik.values.clinicId === "other" && (
                <InputField
                  id="clinicName"
                  name="clinicName"
                  placeholder="Eneter Clinic Name"
                  value={formik.values.clinicName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.clinicName && Boolean(formik.errors.clinicName)}
                  helperText={formik.touched.clinicName && formik.errors.clinicName}
                />
              )}
            </Box>

            <Box>
              <InputLabel>Area of Interest</InputLabel>
              <MultiSelect
                id={"tags"}
                name={"tags"}
                labelId={"select-tags-label"}
                options={tagsOptions}
                selectValue={formik.values.tags}
                setSelectValue={(value) => {
                  formik.setFieldValue("tags", value);
                }}
                placeholder={"Select Tags"}
                error={formik.touched.tags && Boolean(formik.errors.tags)}
                helperText={formik.touched.tags && formik.errors.tags}
              />
            </Box>

            <Box>
              <InputLabel htmlFor="medicalDesignation">Medical Designation</InputLabel>
              <Select
                native
                style={{ width: "100%" }}
                id="medicalDesignation"
                name="medicalDesignation"
                value={formik.values.medicalDesignation}
                onChange={(e: any) => {
                  formik.setFieldValue("medicalDesignation", e.target.value);
                }}
              >
                {MEDICAL_DESIGNATIONS.map((md: any, index: number) => (
                  <option key={index} value={md.value}>
                    {md.label}
                  </option>
                ))}
              </Select>
            </Box>

            <InputField
              label="Account Name"
              id="accountName"
              name="accountName"
              disabled
              value={formik.values.accountName}
              fullWidth
            />

            <Box>
              <InputLabel>Channels</InputLabel>
              <MultiSelect
                id={"channels"}
                name={"channels"}
                labelId={"select-channels-label"}
                options={channels}
                selectValue={formik.values.channels}
                setSelectValue={(value) => {
                  formik.setFieldValue("channels", value);
                }}
                placeholder={"Select Channels"}
                error={formik.touched.channels && Boolean(formik.errors.channels)}
                helperText={formik.touched.channels && formik.errors.channels}
                showSelectAll={false}
              />
            </Box>

            <InputField
              label="Subscriber Location"
              id="region"
              name="region"
              disabled
              value={formik.values.region}
              fullWidth
            />

            <InputField
              label="Zip Code"
              id="zip"
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
              fullWidth
            />

            {user?.isSuperAdmin && (
              <Box>
                <InputLabel htmlFor="onboardingStatus">Onboarding Status</InputLabel>
                <Select
                  native
                  style={{ width: "100%" }}
                  id="onboardingStatus"
                  name="onboardingStatus"
                  value={formik.values.onboardingStatus}
                  onChange={(e: any) => {
                    formik.setFieldValue("onboardingStatus", e.target.value);
                  }}
                >
                  {ONBOARDING_STATUS_OPTIONS.map((md: any, index: number) => (
                    <option key={index} value={md.value}>
                      {md.label}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            {formik.values.referredBy && (
              <InputField
                label="Referred By"
                id="referredBy"
                name="referredBy"
                disabled
                value={formik.values.referredBy}
                fullWidth
              />
            )}

            <FormGroup
              onChange={(event: any) => {
                const values = {
                  ...formik.values.sendType,
                  [event.target.name]: event.target.checked,
                };
                if (Object.values(values).includes(true)) {
                  formik.setFieldValue("sendType", values);
                }
              }}
            >
              <FormLabel>Send Type</FormLabel>
              <div className="ml-4">
                <FormControlLabel
                  control={<Checkbox checked={formik.values.sendType.sms} />}
                  label="SMS"
                  name="sms"
                />
                <FormControlLabel
                  control={<Checkbox checked={formik.values.sendType.email} />}
                  label="Email"
                  name="email"
                />
                <FormHelperText>Select at least one option.</FormHelperText>
              </div>
            </FormGroup>

            <FormGroup>
              <div className="ml-4">
                <FormControlLabel
                  control={<Checkbox checked={formik.values.isDoctor} />}
                  label="Subscriber is a Doctor?"
                  name="isDoctor"
                  onChange={formik.handleChange}
                />
              </div>
            </FormGroup>

            <Grid container>
              <Grid item sm={8} xs={12}>
                {errors && errors.length > 0 && (
                  <ErrorComponent
                    title="Validation Error"
                    errors={errors}
                    sx={{ margin: 0 }}
                  />
                )}
              </Grid>
              <Grid item sm={4} xs={12}>
                <Box textAlign={"right"}>
                  <LoadingButton
                    type="submit"
                    size="large"
                    variant="contained"
                    loading={isLoading}
                  >
                    Save Subscriber
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Card>
    </Container>
  );
};

export default AddEditSubscriber;
