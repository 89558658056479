import { getHeader } from "helpers/utils";
import moment from "moment";
import { ClinicType } from "typings/clinic";
import { UpdateVideoType } from "typings/channel";
// import { CreateAccountRequest, UpdateAccountRequest } from "typings/account";

const API_URL = process.env.REACT_APP_API_URL;

const fetchData = async (url: string, query: string, variables: any = {}) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: getHeader().headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    if (!response.ok) {
      const result = await response.json();
      console.log("error", result);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error: any) {
    console.error("Error fetching GraphQL data:", error);
    throw new Error("Failed to fetch data");
  }
};

//admin
export const getUsers = async (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/graphql`;

  const query = `
    query GetUsers($page: Int!, $limit: Int!) {
      getUsers(page: $page, limit: $limit) {
        rows {
          id
          first_name
          last_name
          email
          mobile
          email
          account_id
          is_super_admin
          status
          created_at
          updated_at
          receive_email_notifications
          admin_role {
            id
            role {
          name
        }
         }}
        count
      }
    }
  `;

  const variables = {
    page: page || 0,
    limit: limit || 20,
    filter: filter || {},
  };

  try {
    const result = await fetchData(url, query, variables);

    // created_at from graphql is unixTimestamp, Convert created_at to ISO 8601 format
    const users = result.data.getUsers.rows.map((user: any) => {
      return {
        ...user,
        created_at: moment(user.created_at).isValid()
          ? moment(user.created_at).toISOString()
          : moment(user.created_at, "x").toISOString(),
      };
    });

    return {
      ...result.data.getUsers,
      rows: users,
    };
  } catch (error) {
    console.error("Error fetching users:", error);
    return { error: "Failed to fetch users" };
  }
};

export const getProfile = async () => {
  let url = `${API_URL}/graphql`;

  const query = `query {
  getProfile {
    id
    first_name
    last_name
    email
    mobile
    account_id
    is_super_admin
    status
    created_at
    updated_at
    receive_email_notifications
    admin_role {
      id
      role {
        name
      }
    }
  }
}`;

  try {
    const result = await fetchData(url, query);

    return result.data.getProfile;
  } catch (error) {
    console.error("Error fetching userProfile:", error);
    return { error: "Failed to fetch userProfile" };
  }
};

export const inviteUser = async (email: string, role: string) => {
  let url = `${API_URL}/graphql`;

  const variables = {
    email,
    role,
  };

  const query = `
    mutation InviteUser($email: String!, $role: String!) {
      inviteUser(input: {
        email: $email,
        role: $role
      }) {
        message
        link
      }
    }
  `;

  try {
    const result = await fetchData(url, query, variables);
    return result.data;
  } catch (error) {
    console.error("Error inviting user:", error);
    throw new Error("Failed to invite user");
  }
};

export const upsertUser = async (input: any) => {
  let url = `${API_URL}/graphql`;

  const variables = {
    firstName: input.firstName,
    lastName: input.lastName,
    email: input.email,
    mobile: input.mobile,
    receiveEmailNotifications: input.receiveEmailNotifications,
  };

  const query = `
    mutation UpsertUser($firstName:String!, $lastName:String!, $email:String!, $mobile:String!, $receiveEmailNotifications:Boolean!) {
        upsertUser(input: {
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            mobile: $mobile,
            receiveEmailNotifications: $receiveEmailNotifications
      }) {
    data
  }
}`;

  try {
    const result = await fetchData(url, query, variables);
    return result.data;
  } catch (error) {
    console.error("Error upserting user:", error);
    throw new Error("Failed to upsert user");
  }
};

export const updateUserRole = async (userId: string, roleName: string) => {
  let url = `${API_URL}/graphql`;

  const variables = {
    id: userId,
    roleName,
  };

  const query = `
      mutation UpdateUserRole($id: String!, $roleName: String!) {
        updateUserRole(input: {
        id: $id,
        roleName: $roleName,
        }) {
      data
    }
  }`;

  try {
    const result = await fetchData(url, query, variables);
    return result.data;
  } catch (error) {
    console.error("Error update user:", error);
    throw new Error("Failed to update userRole");
  }
};

//accounts
export const getAccount = async () => {
  let url = `${API_URL}/graphql`;

  const query = `query {
    account {
      id
      name
      slug
      email
      logo
      primary_color
      secondary_color
      settings {
        pollBySms
        hideOnRegister
        welcomeVideos {
          id
          video
          thumbnail
          order
        }
      }
      parent_account_id
      parent_account_name
    }
  }`;

  try {
    const result = await fetchData(url, query);
    return result.data.account;
  } catch (error) {
    console.error("Error fetching account:", error);
    return { error: "Failed to fetch account" };
  }
};

export const getAccounts = async (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/graphql`;

  const query = `
    query GetAccounts($page: Int!, $limit: Int!) {
      accounts(page: $page, limit: $limit) {
        rows {
          id
          name
          slug
          email
          logo
          primary_color
          secondary_color
          settings {
            pollBySms
            hideOnRegister
            welcomeVideos {
              id
              video
              thumbnail
              order
            }
          }
          created_at
          parent_account_id
          parent_account_name
        }
        count
      }
    }`;

  const variables = {
    page: page || 0,
    limit: limit || 20,
    filter: filter || {},
  };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.accounts;
  } catch (error) {
    console.error("Error fetching accounts:", error);
    return { error: "Failed to fetch accounts" };
  }
};

//should move to unauthorized endpoint
export const getTheme = async (slug: string) => {
  let url = `${API_URL}/graphql`;

  const query = `query GetTheme($slug: String!) {
        theme(slug: $slug) {
        logo
        primaryColor
        secondaryColor
        }
    }`;

  const variables = {
    slug: slug,
  };

  try {
    const result = await fetchData(url, query, variables);
    // console.log("result", result);
    return result.data.theme;
  } catch (error) {
    console.error("Error fetching theme:", error);
    return { error: "Failed to fetch theme" };
  }
};
//should move to unauthorized endpoint
export const getSlug = async (slug: string) => {
  let url = `${API_URL}/graphql`;

  const query = `query GetAccountSlug($accountName: String!) {
  getSlug(accountName: $accountName)
}`;

  const variables = {
    slug: slug,
  };

  try {
    const result = await fetchData(url, query, variables);
    // console.log("result", result);
    return result.data.getSlug;
  } catch (error) {
    console.error("Error fetching slug:", error);
    return { error: "Failed to fetch slug" };
  }
};

// export const createAccount = async (data: CreateAccountRequest) => {
//   let url = `${API_URL}/graphql`;
// };

//channels
export const getChannels = async (limit?: number, page?: number) => {
  let url = `${API_URL}/graphql`;

  const query = `query GetChannels($limit: Int, $page: Int) {
  getChannels(limit: $limit, page: $page) {
    data
  }
}`;

  const variables = {
    page: page || 0,
    limit: limit || 20,
  };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getChannels;
  } catch (error) {
    console.error("Error fetching channels:", error);
    return { error: "Failed to fetch channels" };
  }
};

export const getChannelsDropdown = async (accountId?: String) => {
  let url = `${API_URL}/graphql`;

  const query = `query GetChannelsDropdown($accountId: String!) {
    getChannelsDropdown(accountId: $accountId) {
        data
    }
    }`;

  const variables = {
    accountId: accountId,
  };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getChannelsDropdown.data;
  } catch (error) {
    console.error("Error fetching channels:", error);
    return { error: "Failed to fetch channels" };
  }
};

//clinics
export const getClinics = async (limit = 10, page = 0, isOther = false) => {
  const url = `${API_URL}/graphql`;
  const query = `
  query GetClinics($page: Int!, $limit: Int!, $isOther: Boolean) {
    getClinics(page: $page, limit: $limit, isOther: $isOther) {
        data
    }
  }
`;
  const variables = { page, limit, isOther };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getClinics;
  } catch (error) {
    console.error("Error fetching clinics:", error);
    return { error: "Failed to fetch clinics" };
  }
};

export const getClinicsDropdown = async (accountId?: string, showHidden?: boolean) => {
  const url = `${API_URL}/graphql`;
  const query = `
  query GetClinicsDropdown($accountId: String, $showHidden: Boolean) {
    getClinicsDropdown(accountId: $accountId, showHidden: $showHidden) {
        data
    }
  }
`;
  const variables = { accountId, showHidden };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getClinicsDropdown;
  } catch (error) {
    console.error("Error fetching public clinics:", error);
    return { error: "Failed to fetch public clinics" };
  }
};

export const addUpdateClinic = async (id: string, data: ClinicType) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation upsertClinic($id: String!, $data: ClinicInput!) {
    upsertClinic(id: $id, input: $data) {
        data
    }
  }
`;
  const variables = { id, data };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.addUpdateClinic;
  } catch (error) {
    console.error("Error adding/updating clinic:", error);
    return { error: "Failed to add/update clinic" };
  }
};

export const deleteClinic = async (id: string) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation DeleteClinic($id: String!) {
    deleteClinic(id: $id) {
        data
    }
  }
`;
  const variables = { id };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.deleteClinic;
  } catch (error) {
    console.error("Error deleting clinic:", error);
    return { error: "Failed to delete clinic" };
  }
};

export const assignClinicToAccount = async (id: string, accountId: string) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation AssignClinicToAccount($id: String!, $accountId: String!) {
    assignClinicToAccount(id: $id, accountId: $accountId) {
      success
      message
    }
  }
`;
  const variables = { id, accountId };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.assignClinicToAccount;
  } catch (error) {
    console.error("Error assigning clinic to account:", error);
    return { error: "Failed to assign clinic to account" };
  }
};

//videos
export const getVideos = async (id: string, filter?: any, limit = 10, page = 0) => {
  const url = `${API_URL}/graphql`;
  const query = `
  query GetVideos($id: String, $filter: JSON, $limit: Int, $page: Int) {
    getVideos(id: $id, filter: $filter, limit: $limit, page: $page) {
        data
    }
  }
`;
  const variables = { id, filter, limit, page };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getVideos;
  } catch (error) {
    console.error("Error fetching videos:", error);
    return { error: "Failed to fetch videos" };
  }
};

export const addUpdateVideo = async (id: string, data: UpdateVideoType) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation UpsertVideo($id: String, $data: JSON) {
    upsertVideo(id: $id, inputs: $data) {
    data
}}
`;
  const variables = { id, data };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.addUpdateVideo;
  } catch (error) {
    console.error("Error adding/updating video:", error);
    return { error: "Failed to add/update video" };
  }
};

export const deleteVideo = async (id: string) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation DeleteVideo($id: String!) {
    deleteVideo(id: $id) {
    data
    }
  }
`;
  const variables = { id };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.deleteVideo;
  } catch (error) {
    console.error("Error deleting video:", error);
    return { error: "Failed to delete video" };
  }
};

//subscribers
export const getSubscribers = async (filter?: any, limit = 10, page = 0) => {
  let url = `${API_URL}/graphql`;

  const query = `
    query GetSubscribers($filter: JSON, $limit: Int, $page: Int) {
      getSubscribers(filter: $filter, limit: $limit, page: $page) {
        data 
}}
  `;
  const variables = { filter, limit, page };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getSubscribers.data;
  } catch (error) {
    console.error("Error fetching subscribers:", error);
    return { error: "Failed to fetch subscribers" };
  }
};

export const getSubscriber = async (id: string) => {
  let url = `${API_URL}/graphql`;

  const query = `
      query GetSubscriber($id: String) {
        getSubscriber(id: $id) {
          data 
  }}
    `;
  const variables = { id };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getSubscriber.data;
  } catch (error) {
    console.error("Error fetching subscribers:", error);
    return { error: "Failed to fetch subscribers" };
  }
};

//tags
export const getTags = async (accountId: string, limit = 10, page = 0) => {
  const url = `${API_URL}/graphql`;
  const query = `
  query GetTags($accountId: String, $limit: Int, $page: Int) {
    getTags(accountId: $accountId, limit: $limit, page: $page) {
        data
    }
  }
`;
  const variables = { accountId, limit, page };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.getTags.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    return { error: "Failed to fetch tags" };
  }
};

export const upsertTag = async (id: string, data: { tag: string }) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation UpsertTag($id: String, $data: JSON) {
    upsertTag(id: $id, input: $data) {
        data
    }
  }
`;
  const variables = { id, data };

  try {
    const result = await fetchData(url, query, variables);
    console.log("result", result);
    return result.data.upsertTag;
  } catch (error) {
    console.error("Error upserting tag:", error);
    return { error: "Failed to upsert tag" };
  }
};

export const deleteTag = async (id: string) => {
  const url = `${API_URL}/graphql`;
  const query = `
  mutation DeleteTag($id: String) {
    deleteTag(id: $id) {
    data
    }
  }
`;
  const variables = { id };

  try {
    const result = await fetchData(url, query, variables);
    return result.data.deleteTag.data;
  } catch (error) {
    console.error("Error deleting tag:", error);
    return { error: "Failed to delete tag" };
  }
};

export const getTopTags = async (accountId?: string) => {
  const url = `${API_URL}/graphql`;
  const query = `
    query TopTags($accountId: String) {
        topTags(accountId: $accountId) {
            data
}}
    `;
  const variables = { accountId };
  try {
    const result = await fetchData(url, query, variables);
    return result.data.topTags.data;
  } catch (error) {
    console.error("Error fetching top tags:", error);
    return { error: "Failed to fetch top tags" };
  }
};
