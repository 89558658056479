import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";

import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

import Modal from "components/Core/Modal";
import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";
// import { addUpdateClinic } from "api/api";
import { addUpdateClinic as gqlAddUpdateClinic } from "api/graphql";
import { ClinicType } from "typings/clinic";
import { useDialog } from "context/dialog";

type Props = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  clinic?: ClinicType;
  refreshList: () => void;
  showAddButton?: boolean;
};

const AddUpdateClinic = ({
  clinic,
  openModal,
  setOpenModal,
  refreshList,
  showAddButton = true,
}: Props) => {
  const { alertSuccess, alertError } = useDialog();

  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initValues: ClinicType = {
    id: "",
    name: "",
    description: "",
  };
  const [initialValues, setInitialValues] = useState<ClinicType>(initValues);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION.clinic_schema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await gqlAddUpdateClinic(values.id || uuid(), {
          name: values.name,
          description: values.description,
        });

        alertSuccess("Clinic added successfully.");

        refreshList();
        setOpenModal(false);
      } catch (err: any) {
        alertError(err.message);
      }
      setIsLoading(false);
      resetForm();
    },
  });

  useEffect(() => {
    if (isNew || !clinic) {
      setInitialValues(initValues);
    } else if (clinic) {
      setInitialValues({
        id: clinic.id,
        name: clinic.name,
        description: clinic.description || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic, isNew]);

  useEffect(() => {
    if (!openModal) {
      formik.resetForm();
      setIsNew(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h4" gutterBottom align="center">
          Clinic
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              id="name"
              name="name"
              label="Clinic Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              labelInsideInputField
            />

            <InputField
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              labelInsideInputField
              multiline
              rows={4}
            />

            <LoadingButton
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      {showAddButton && (
        <Box sx={{ textAlign: "right" }} p={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setIsNew(true);
              setOpenModal(true);
            }}
            size="large"
          >
            New Clinic
          </Button>
        </Box>
      )}
    </>
  );
};

export default AddUpdateClinic;
