import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { getAccountsList, getVideoWatchTimeReport, getVideosMetrics } from "api/api";
import { getTopTags } from "api/graphql";
import Select from "components/Core/Inputs/Select";
import moment from "moment";
import GridCard from "./Card";
import TopVideoChart from "./TopVideosChart";
import MdChart from "./MdChart";
import RegionChart from "./RegionChart";
import AoiChart from "./AoiChart";
import AccountsSubscribers from "./AccountsSubscribers";
import VideoViewChart from "./VideoViewChart";
import { capitalize, secondsToTime } from "helpers/utils";
import pages from "enums/pages";
import Header from "./DashboardHeader";
import { QUICK_FILTER_OPTIONS } from "enums/dropdown";
import { BEGINNING_DATE } from "enums/common";
import VideoWatchTimeChart from "./VideoWatchTimeChart";
import { useDialog } from "context/dialog";

type ChartProps = { labels: string[]; labelLinks?: string[]; data: number[] };
type OptionType = { label: string; value: string };

const BusinessDashboard = () => {
  const { showPageLoading } = useDialog();
  const [quickFilterVal, setQuickFilter] = useState<number>(
    QUICK_FILTER_OPTIONS[0].value,
  );

  const [totalVideosSent, setTotalVideosSent] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [avgPlaytime, setAvgPlaytime] = useState(0);
  const [openedVideosCount, setOpenedVideosCount] = useState(0);
  const [topTags, setTopTags] = useState<ChartProps>();
  const [watchTimeReport, setWatchTimeReport] = useState<any>();
  const [topVideos, setTopVideos] = useState<ChartProps>();
  const [videosViewsOverTime, setVideosViewsOverTime] = useState<ChartProps>();
  const [accountId, setAccountId] = useState("all");
  const [accounts, setAccounts] = useState<OptionType[]>([
    {
      label: "All",
      value: "all",
    },
  ]);

  const [subscribersOfMd, setSubscribersOfMd] = useState<ChartProps>();
  const [subscribersOfRegion, setSubscribersOfRegion] = useState<ChartProps>();
  const [subscribersOfAccount, setSubscribersOfAccount] = useState<ChartProps>();

  const getVideoMeticsData = useCallback(async () => {
    showPageLoading(true);
    try {
      const fromDate = quickFilterVal
        ? moment().subtract(quickFilterVal, "days").format("YYYY-MM-DD")
        : moment(BEGINNING_DATE).format("YYYY-MM-DD");
      const toDate = moment().format("YYYY-MM-DD");

      if (fromDate && toDate) {
        const data: any = await getVideosMetrics(fromDate, toDate, accountId);

        setTotalVideosSent(data.totalVideosSent);
        setTotalViews(data.videoViewCount);
        setTotalPlayTime(data.totalPlaytime);
        setAvgPlaytime(data.avgPlaytime);
        setOpenedVideosCount(data.totalOpenedVideos);
        setTopVideos({
          labels: data.topVideos.map((v: any) => v.title),
          labelLinks: data.topVideos.map(
            (v: any) =>
              pages.VIDEO_DASHBOARD_PAGE.replace(":videoId", v.id) + `?name=${v.title}`,
          ),
          data: data.topVideos.map((v: any) => v.count),
        });

        let days: string[] = [];
        let viewsCounts: number[] = [];

        if (quickFilterVal) {
          days = [...Array(quickFilterVal)].map((_, i) =>
            moment().subtract(i, "days").format("YYYY-MM-DD"),
          );
          viewsCounts = [...Array(quickFilterVal)].map(() => 0);
          data.videosViewedOverTime.map(
            (r: any) => (viewsCounts[days.indexOf(r.day)] = r.count),
          );
        } else {
          days = data.videosViewedOverTime.map((r: any) => r.day);
          viewsCounts = data.videosViewedOverTime.map((r: any) => r.count);
        }

        setVideosViewsOverTime({
          labels: days,
          data: viewsCounts,
        });

        if (accountId === "all") {
          setSubscribersOfMd({
            labels: data.subscribersCountPerInstitution.map(
              (s: any) => s.medical_designation,
            ),
            data: data.subscribersCountPerInstitution.map((s: any) => s.count),
          });
          setSubscribersOfRegion({
            labels: data.subscribersCountPerRegion.map((s: any) => capitalize(s.region)),
            data: data.subscribersCountPerRegion.map((s: any) => s.count),
          });
          setSubscribersOfAccount({
            labels: data.subscribersCountPerAccount.map((s: any) =>
              capitalize(s.account_name),
            ),
            data: data.subscribersCountPerAccount.map((s: any) => s.subscribers_count),
          });
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilterVal, accountId]);

  useEffect(() => {
    getVideoMeticsData();
  }, [getVideoMeticsData]);

  const getTagData = useCallback(async () => {
    const [topTags]: any = await Promise.all([getTopTags(accountId)]);
    setTopTags({
      labels: topTags.map((t: any) => t.tag),
      data: topTags.map((t: any) => t.count),
    });
  }, [accountId]);

  useEffect(() => {
    getTagData();
  }, [getTagData]);

  const accountsList = useCallback(async () => {
    const data: any = await getAccountsList();
    setAccounts([
      {
        label: "All",
        value: "all",
      },
      ...data.accounts.map((a: any) => ({ label: a.name, value: a.id })),
    ]);
  }, []);

  useEffect(() => {
    accountsList();
  }, [accountsList]);

  const videoWatchTimeReport = useCallback(async () => {
    const data: any = await getVideoWatchTimeReport();

    setWatchTimeReport({
      labels: data.report.map((d: any) =>
        moment().utc().set({ h: d.hrs }).local().format("HH:00"),
      ),
      data: data.report.map((d: any) => d.count),
    });
  }, []);

  useEffect(() => {
    videoWatchTimeReport();
  }, [videoWatchTimeReport]);

  const gridData = [
    {
      title: "Videos Sent",
      helperText: "videos Sent",
      stats: totalVideosSent,
    },
    {
      title: "Open",
      helperText: "videos opened",
      stats: openedVideosCount,
    },
    {
      title: "Full View",
      helperText: "full views",
      stats: totalViews,
    },
    {
      title: "Playtime",
      helperText: "",
      stats: secondsToTime(totalPlayTime) || "0s",
    },
    {
      title: "Avg playtime",
      helperText: "",
      stats: secondsToTime(avgPlaytime) || "0s",
    },
  ];

  return (
    <Container maxWidth={"xl"}>
      <Header>
        <Typography variant="h4">Business Metrics</Typography>
      </Header>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <Typography variant="h5">
            {QUICK_FILTER_OPTIONS.find((o) => o.value === quickFilterVal)?.label}
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width={410}
        >
          <Box width={200}>
            <Select
              id="quickFilter"
              label="Quick Filter"
              name="quickFilter"
              options={QUICK_FILTER_OPTIONS}
              value={quickFilterVal}
              onChange={(val: number) => setQuickFilter(val)}
            />
          </Box>
          <Box width={200}>
            <Select
              id="accounts"
              label="Accounts"
              name="accounts"
              options={accounts}
              value={accountId}
              onChange={(val: string) => setAccountId(val)}
            />
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={2} mb={3}>
        {gridData.map((d: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl>
            <GridCard title={d.title} helperText={d.helperText} stats={d.stats} />
          </Grid>
        ))}
      </Grid>

      {accountId === "all" ? (
        <>
          <Grid container spacing={2} mb={3}>
            <Grid item xs={12} md={4} sx={{ height: "auto" }}>
              <RegionChart
                labels={subscribersOfRegion?.labels || []}
                data={subscribersOfRegion?.data || []}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ height: "auto" }}>
              <MdChart
                labels={subscribersOfMd?.labels || []}
                data={subscribersOfMd?.data || []}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ height: "auto" }}>
              <AccountsSubscribers
                labels={subscribersOfAccount?.labels || []}
                data={subscribersOfAccount?.data || []}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={8}>
          <TopVideoChart
            labels={topVideos?.labels || []}
            data={topVideos?.data || []}
            labelsLink={topVideos?.labelLinks}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: "auto" }}>
          <AoiChart labels={topTags?.labels || []} data={topTags?.data || []} />
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={accountId === "all" ? 8 : 12}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                mb={2}
                spacing={1}
              >
                <Typography fontSize={18} mb={3}>
                  Videos Viewed Over Time
                </Typography>
              </Stack>
              <VideoViewChart
                labels={videosViewsOverTime?.labels || []}
                data={videosViewsOverTime?.data || []}
              />
            </CardContent>
          </Card>
        </Grid>
        {accountId === "all" ? (
          <Grid item xs={12} md={4} sx={{ height: "auto" }}>
            <VideoWatchTimeChart
              labels={watchTimeReport?.labels || []}
              data={watchTimeReport?.data || []}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  );
};

export default BusinessDashboard;
