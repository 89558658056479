import Confirm from "components/Core/Confirm/Confirm";
import { ClinicType } from "typings/clinic";
import { useDialog } from "context/dialog";

// import { deleteClinic } from "api/api";
import { deleteClinic as gqlDeleteClinic } from "api/graphql";

type Props = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  clinic: ClinicType;
  refreshList: () => void;
};

const DeleteClinic = ({ clinic, openModal, setOpenModal, refreshList }: Props) => {
  const { alertSuccess, alertError, showPageLoading } = useDialog();

  const handleDeleteClinic = async () => {
    if (!clinic.id) return;
    showPageLoading(true);
    setOpenModal(false);
    try {
      await gqlDeleteClinic(clinic.id);
      alertSuccess("Clinic deleted successfully.");
      refreshList();
    } catch (err: any) {
      alertError(err.message);
    }
    showPageLoading(false);
  };

  return (
    <Confirm
      isOpen={openModal}
      title={`Are you sure you want to delete ${clinic.name} clinic?`}
      confirmBtnText={"Yes"}
      cancelBtnText={"No"}
      confirmfn={handleDeleteClinic}
      cancelfn={() => setOpenModal(false)}
    />
  );
};

export default DeleteClinic;
