import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";

import { deleteChannel } from "api/api";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreOptions from "components/Core/MoreOptions";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import { ChannelType, GetChannelsResponse } from "typings/channel";
import AddUpdateChannel from "./AddChannel";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";

// import {getChannels} from "api/api";
import { getChannels as gqlGetChannels } from "api/graphql";

const Channels = () => {
  const limit = 20;
  const { confirm, alertSuccess, alertError, showPageLoading } = useDialog();

  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [channels, setChannels] = useState<ChannelType[]>([]);
  const [currentChannel, setCurrentChannels] = useState<ChannelType>();

  const [showAddChannelModal, setShowAddChannelModal] = useState(false);

  const TABLE_HEAD = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "createdDate", label: "Created Date" },
    { id: "", label: "" },
  ];

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const getData = useCallback(() => {
    showPageLoading(true);
    gqlGetChannels(limit, page)
      .then((res) => {
        const data = res.data as GetChannelsResponse;
        setChannels(data.rows);
        setTotalCount(data.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => showPageLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const handleDeleteChannel = async (id: string) => {
    showPageLoading(true);
    try {
      await deleteChannel(id);
      alertSuccess("Channel deleted successfully.");
      getData(); // refresh session
    } catch (err: any) {
      alertError(err.message);
    }
    showPageLoading(false);
  };

  const deleteChannelConfirmation = (id: string, name: string) => {
    confirm({
      title: "Delete Channel!",
      content: `Are you sure you want to delete ${name} channel?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => handleDeleteChannel(id),
    });
  };

  return (
    <Card>
      <AddUpdateChannel
        openModal={showAddChannelModal}
        setOpenModal={setShowAddChannelModal}
        channel={currentChannel}
        refreshList={getData}
      />

      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {channels.map((row: ChannelType) => {
                const { id, name, description, created_at, is_default } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell>
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        {name}
                      </Stack>
                    </TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{formatDate(created_at)}</TableCell>
                    <TableCell align="right">
                      {!is_default && (
                        <MoreOptions
                          options={[
                            {
                              label: "Edit Channel",
                              icon: <EditIcon width={24} height={24} />,
                              onClick: () => {
                                setCurrentChannels({
                                  id,
                                  name,
                                  description,
                                });
                                setShowAddChannelModal(true);
                              },
                            },
                            {
                              label: "Delete Channel",
                              icon: <DeleteIcon width={24} height={24} />,
                              onClick: () => deleteChannelConfirmation(id, name),
                            },
                          ]}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </Scrollbar>
    </Card>
  );
};

export default Channels;
