import axios, { AxiosRequestConfig } from "axios";
import { getHeader } from "helpers/utils";
import { UpdateVideoType, UpsertChannelType } from "typings/channel";
import { CreateAccountRequest, UpdateAccountRequest } from "typings/account";
import { ObjectType } from "typings/common";
import { SubscriberType, UpdateSubscriptionProps } from "typings/subscriber";
import { ClinicType } from "typings/clinic";

const API_URL = process.env.REACT_APP_API_URL;

export type Method = "get" | "delete" | "post" | "put" | "patch";

export const prepareQueryParams = (data: ObjectType) => {
  const query = Object.entries(data)
    .filter(([value]) => (value ? true : false))
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return query ? "?" + query : "";
};

export const apiCall = (
  method: Method,
  url: string,
  options: AxiosRequestConfig = {},
) => {
  return new Promise((resolve, reject) => {
    axios
      .request({
        method,
        url,
        ...options,
      })
      .then((res) => resolve(res.data))
      .catch((error) => {
        if (error.response) {
          return reject(error.response.data.error);
        } else if (error.request) {
          return reject("Request failed");
        } else {
          return reject(error);
        }
      });
  });
};

export const getVideos = (id: string, filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/videos`;
  if (id) url += `/${id}`;
  url += prepareQueryParams({
    ...filter,
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const fileUploadUrl = (filename: string, type: string, dir: string) => {
  let url = `${API_URL}/file`;
  url += prepareQueryParams({
    filename: filename,
    type: type,
    dir: dir,
  });
  return apiCall("get", url, getHeader());
};

export const uploadVideoUsingS3SignedUrl = (url: string, file: File, type: string) =>
  apiCall("put", url, {
    data: file,
    headers: {
      "Content-Type": type,
    },
  });

export const getVideoUploadUrl = (id: string, videoType: string) =>
  apiCall("post", `${API_URL}/mux/videos`, { data: { id, videoType }, ...getHeader() });

export const getMuxVideoLink = (id: string) =>
  apiCall("get", `${API_URL}/mux/videos/${id}`, getHeader());

export const addVideo = (data: any) =>
  apiCall("post", `${API_URL}/video`, {
    data,
    ...getHeader(),
  });

export const addUpdateVideo = (id: string, data: UpdateVideoType) =>
  apiCall("post", `${API_URL}/videos/${id}`, {
    data,
    ...getHeader(),
  });

export const deleteVideo = (id: string) =>
  apiCall("delete", `${API_URL}/videos/${id}`, getHeader());

export const uploadVideo = (id: string, data: any) =>
  apiCall("post", `${API_URL}/videos/${id}/upload`, {
    data,
    ...getHeader(),
  });

export const completeUpload = (id: string, data: any) =>
  apiCall("post", `${API_URL}/videos/${id}/complete`, {
    data,
    ...getHeader(),
  });

export const getChannels = (limit?: number, page?: number) => {
  let url = `${API_URL}/channels`;

  url += prepareQueryParams({
    page: page || 0,
    limit: limit || 10,
  });

  return apiCall("get", url, getHeader());
};

export const upsertChannel = (id: string, data: UpsertChannelType) =>
  apiCall("post", `${API_URL}/channels/${id}`, {
    data,
    ...getHeader(),
  });

export const deleteChannel = (id: string) =>
  apiCall("delete", `${API_URL}/channels/${id}`, getHeader());

export const getChannelVideos = (
  channelId: string,
  filter?: any,
  limit?: number,
  page?: number,
) => {
  let url = `${API_URL}/channels/${channelId}/videos`;

  url += prepareQueryParams({
    page: page || 0,
    limit: limit || 10,
    ...filter,
  });

  return apiCall("get", url, getHeader());
};

export const addVideosToChannel = (channelId: string, videoIds: string[]) =>
  apiCall("post", `${API_URL}/channels/${channelId}/videos`, {
    data: {
      videoIds: videoIds,
    },
    ...getHeader(),
  });

export const addSubscribersToChannel = (channelId: string, subscribersIds: string[]) =>
  apiCall("post", `${API_URL}/channels/${channelId}/subscribers`, {
    data: {
      subscribersIds,
    },
    ...getHeader(),
  });

export const removeVideoFromChannel = (channelId: string, videoId: string) =>
  apiCall("delete", `${API_URL}/channels/${channelId}/videos`, {
    data: {
      videoIds: [videoId],
    },
    ...getHeader(),
  });

export const getAccount = () => apiCall("get", `${API_URL}/account`, getHeader());

export const getAccountsDropdown = (excludeih?: boolean) => {
  const queryParmas = excludeih ? prepareQueryParams({ excludeih }) : "";
  return apiCall("get", `${API_URL}/dropdown/accounts` + queryParmas);
};

export const updateAccount = (data: UpdateAccountRequest) =>
  apiCall("post", `${API_URL}/account`, {
    data,
    ...getHeader(),
  });

export const getUsers = (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/users`;
  url += prepareQueryParams({
    ...filter,
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const getUser = () => {
  let url = `${API_URL}/user`;
  return apiCall("get", url, getHeader());
};

export const upsertUser = (data: any) =>
  apiCall("post", `${API_URL}/users`, {
    data,
    ...getHeader(),
  });

export const inviteUser = (email: string, role: string) =>
  apiCall("post", `${API_URL}/invite`, {
    data: {
      email,
      role,
    },
    ...getHeader(),
  });

export const getAccounts = (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/accounts`;
  url += prepareQueryParams({
    ...filter,
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const createAccount = (data: CreateAccountRequest) =>
  apiCall("post", `${API_URL}/accounts`, {
    data,
    ...getHeader(),
  });

export const getSlug = (name: string) => apiCall("get", `${API_URL}/slug?name=${name}`);

export const getTheme = (name: string) => apiCall("get", `${API_URL}/${name}`);

export const getAccountsList = () => apiCall("get", `${API_URL}/dropdown/accounts`);

export const addSubscriber = (data: SubscriberType) =>
  apiCall("post", `${API_URL}/subscribe`, {
    data,
    ...getHeader(),
  });

export const updateSubscription = (data: UpdateSubscriptionProps) =>
  apiCall("post", `${API_URL}/subscriber`, {
    data,
    ...getHeader(),
  });

export const getTags = (accountId: string, limit?: number, page?: number) => {
  let url = `${API_URL}/accounts/${accountId}/tags`;
  url += prepareQueryParams({
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const upsertTag = (id: string, data: { tag: string }) =>
  apiCall("post", `${API_URL}/tags/${id}`, {
    data,
    ...getHeader(),
  });

export const deleteTag = (id: string) =>
  apiCall("delete", `${API_URL}/tags/${id}`, getHeader());

export const updateAoi = (data: any) =>
  apiCall("post", `${API_URL}/aoi`, {
    data,
  });

export const addPoll = (pollId: string, videoId: string, data: any) =>
  apiCall("post", `${API_URL}/videos/${videoId}/polls/${pollId}`, {
    data,
    ...getHeader(),
  });

export const savePollsOrder = (videoId: string, polls: any) =>
  apiCall("post", `${API_URL}/videos/${videoId}/polls/order`, {
    data: { polls },
    ...getHeader(),
  });

export const deletePoll = (pollId: string, videoId: string) =>
  apiCall("delete", `${API_URL}/videos/${videoId}/polls/${pollId}`, getHeader());

export const getPollsStats = (id: string) =>
  apiCall("get", `${API_URL}/stats/videos/${id}/polls`, getHeader());

export const getPolls = (id: string, pid: string) =>
  apiCall("get", `${API_URL}/videos/${id}/polls/${pid}`);

export const getVideoLink = (id: string, short: boolean, sid: string) => {
  let url = `${API_URL}/videos/${id}/link`;
  url += prepareQueryParams({
    short: short,
    sid,
  });
  return apiCall("get", url);
};

export const watchedVideo = (id: string, data: any) =>
  apiCall("post", `${API_URL}/videos/${id}/watch`, { data });

export const submitPollResponse = (id: string, pollId: string, data: any) =>
  apiCall("post", `${API_URL}/videos/${id}/polls/${pollId}/response`, { data });

export const getSubscribers = (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/subscribers`;
  url += prepareQueryParams({
    ...filter,
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const getSubscriber = (id: string) => {
  let url = `${API_URL}/subscribers/${id}`;
  return apiCall("get", url, getHeader());
};

export const updateSubscriber = (id: string, data: any) => {
  let url = `${API_URL}/subscribers/${id}`;
  return apiCall("post", url, {
    data,
    ...getHeader(),
  });
};

export const reSendCustomizationLink = (id: string) =>
  apiCall("post", `${API_URL}/subscribers/${id}/customization`, {
    data: {},
    ...getHeader(),
  });

export const approveOrRejectSub = (id: string, status: string) =>
  apiCall("post", `${API_URL}/subscribers/${id}/approve`, {
    data: { status },
    ...getHeader(),
  });

export const deleteSubscriber = (id: string) =>
  apiCall("delete", `${API_URL}/subscribers/${id}`, {
    ...getHeader(),
  });

export const subscriberVideoLogs = (id: string, page?: number, limit?: number) => {
  let url = `${API_URL}/subscribers/${id}/videologs`;
  url += prepareQueryParams({ page, limit });
  return apiCall("get", url, getHeader());
};

export const getClinics = (limit?: number, page?: number, isOther = false) => {
  let url = `${API_URL}/clinics`;
  url += prepareQueryParams({
    page: page || 0,
    limit: limit || 10,
    isOther: isOther,
  });
  return apiCall("get", url, getHeader());
};

export const getPublicClinics = (accountId?: string) => {
  let url = `${API_URL}/dropdown/clinics`;
  if (accountId) url += `?accountId=${accountId}`;
  return apiCall("get", url);
};

export const getChannelsDropdown = (accountId?: string) => {
  let url = `${API_URL}/dropdown/channels`;
  if (accountId) url += `?accountId=${accountId}`;
  return apiCall("get", url);
};

export const addUpdateClinic = (id: string, data: ClinicType) =>
  apiCall("post", `${API_URL}/clinics/${id}`, {
    data,
    ...getHeader(),
  });

export const deleteClinic = (id: string) =>
  apiCall("delete", `${API_URL}/clinics/${id}`, getHeader());

export const getRoles = () => apiCall("get", `${API_URL}/roles`, getHeader());

export const updateUserRole = (userId: string, roleName: string) =>
  apiCall("post", `${API_URL}/users/${userId}/role`, {
    data: { roleName },
    ...getHeader(),
  });

export const confirmAccount = (username: string, code: string, type: string) =>
  apiCall("post", `${API_URL}/verify`, {
    data: { username, code, type },
  });

export const resetPassword = (
  username: string,
  code: string,
  type: string,
  password: string,
) =>
  apiCall("post", `${API_URL}/verify`, {
    data: { username, code, type, password },
  });

export const getMessages = (admin?: boolean) =>
  apiCall("get", `${API_URL}/messages${admin ? "?admin=true" : ""}`, getHeader());

export const saveMessage = (id: string, type: string, message: string, admin?: boolean) =>
  apiCall("post", `${API_URL}/messages/${id}${admin ? "?admin=true" : ""}`, {
    data: { type, message },
    ...getHeader(),
  });

export const updateMessageStatus = (id: string, status: string, admin?: boolean) =>
  apiCall("patch", `${API_URL}/messages/${id}${admin ? "?admin=true" : ""}`, {
    data: { status },
    ...getHeader(),
  });

export const deleteMessage = (id: string, admin?: boolean) =>
  apiCall(
    "delete",
    `${API_URL}/messages/${id}${admin ? "?admin=true" : ""}`,
    getHeader(),
  );

export const getTopTags = (accountId?: string) =>
  apiCall(
    "get",
    `${API_URL}/stats/accounts/${accountId ? accountId + "/" : ""}tags`,
    getHeader(),
  );

export const getVideosMetrics = (fromDate: string, toDate: string, accountId?: string) =>
  apiCall(
    "get",
    `${API_URL}/stats/accounts/${
      accountId ? accountId + "/" : ""
    }videos?fromDate=${fromDate}&toDate=${toDate}`,
    getHeader(),
  );

export const getSubscriberVideoMetrics = (
  subscriberId: string,
  fromDate: string,
  toDate: string,
) =>
  apiCall(
    "get",
    `${API_URL}/stats/subscribers/${subscriberId}/videos?fromDate=${fromDate}&toDate=${toDate}`,
    getHeader(),
  );

export const getVideoLogs = (videoId: string, page: number, limit: number) => {
  let url = `${API_URL}/videos/${videoId}/videologs`;
  url += prepareQueryParams({ page, limit });
  return apiCall("get", url, getHeader());
};

export const assignClinicToAccount = (id: string, accountId: string) =>
  apiCall("post", `${API_URL}/clinics/${id}/assign`, {
    data: { accountId },
    ...getHeader(),
  });

export const getVideoMetrics = (
  videoId: string,
  videoType: string,
  fromDate: string,
  toDate: string,
) =>
  apiCall(
    "get",
    `${API_URL}/stats/videos/${videoId}?fromDate=${fromDate}&toDate=${toDate}&videoType=${videoType}`,
    getHeader(),
  );

export const click = (data: any) =>
  apiCall("post", `${API_URL}/click`, {
    data,
  });

export const rateVideo = (videoId: string, subscriberId: string, rating: number) => {
  let url = `${API_URL}/videos/${videoId}/rate`;
  return apiCall("post", url, {
    data: { subscriberId, rating },
  });
};

export const smsTest = (
  videoId: string,
  data: { subscriberId: string; sendVideo: boolean; sendPoll: boolean; isShort: boolean },
) => {
  let url = `${API_URL}/videos/${videoId}/test`;
  return apiCall("post", url, {
    data: data,
    ...getHeader(),
  });
};

export const getSubscribersForDropDown = (
  search?: string,
  limit?: number,
  page?: number,
) => {
  let url = `${API_URL}/dropdown/subscribers`;
  url += prepareQueryParams({
    search: search || "",
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const getVideoWatchTimeReport = () =>
  apiCall("get", `${API_URL}/stats/videos/watchtime`, getHeader());

export const getReports = (
  report: string,
  channelId: string,
  from: any,
  to: any,
  short: boolean,
  seriesId: string,
) => {
  let url = `${API_URL}/reports`;

  url += prepareQueryParams({
    report,
    from,
    to,
    short,
    channelId,
    seriesId,
  });
  return apiCall("get", url, getHeader());
};

export const getSubscribersReports = (selectedAccountIds: any) => {
  let url = `${API_URL}/subscribersReports`;

  url += prepareQueryParams({ selectedAccountIds });
  return apiCall("get", url, getHeader());
};

export const sendCustomSMS = (data: any) =>
  apiCall("post", `${API_URL}/sendsms`, {
    data,
    ...getHeader(),
  });

export const getVideoSeries = () => {
  let url = `${API_URL}/videoseries`;
  return apiCall("get", url, getHeader());
};

export const getApiKey = () => {
  let url = `${API_URL}/api/key`;
  return apiCall("get", url, getHeader());
};

export const reCreateApiKey = () => {
  let url = `${API_URL}/api/key`;
  return apiCall("post", url, getHeader());
};

export const getDoctorsDropdown = () => {
  let url = `${API_URL}/dropdown/doctors`;
  return apiCall("get", url, getHeader());
};

export const addDoctor = (data: any) =>
  apiCall("post", `${API_URL}/doctors`, {
    data,
    ...getHeader(),
  });
